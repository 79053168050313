import * as types from '../actions/types';

const initialState = {
  data: null,
  loading: false
}

export default function stats (state = initialState, action) {
  switch (action.type) {
    
    case types.SET_STATS_DATA:
      return {
        ...state,
        data: action.payload
      }
    
    case types.SET_STATS_LOADING:
      return {
        ...state,
        loading: action.payload
      }
      
    default:
      return state;
  }
}
