import React, { useEffect, useState } from 'react'
import styles from './styles.module.scss'
import { useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { getStats } from '../../actions/stats'
import ReactLoader from 'react-loader'
import parse from 'html-react-parser'
import moment from 'moment'
import { IoIosArrowDown } from 'react-icons/io'
import Checkbox from '../../components/CustomCheckbox/CustomCheckbox';
import RadioButton from '../../components/Checkbox';
import { PieChart, Pie, Cell } from 'recharts';
import { interpolateColor } from '../../utils/smallHelpers'



const Stats = () => {
  
  const dispatch = useDispatch()
  const { statId } = useParams()
  const {loading, data} = useSelector(state => state.user_stat)

  const [statOpen, setStatOpen] = useState(false)


  useEffect(() => {
    dispatch(getStats(statId))
  }, [statId])
  

  const dateTime = (date) => moment(date).format('DD.MM.YYYY HH:mm')

  const wrongAnswers = () => {
    const arrowStyles = {transform: `rotate(${!statOpen ? '0' : '180deg'})`}
    return (
      <div className={styles.wrongQuestionsBlock}>
        <h2 onClick={() => setStatOpen(!statOpen)}>
          Просмотреть допущенные ошибки:
          <IoIosArrowDown style={arrowStyles} size={34}/>
        </h2>
        <ul style={{height: statOpen ? '100%' : '0px'}}>{data.user_answers_to_questions.map(renderQuestion)}</ul>
      </div>
    )
  }

  const renderQuestion = (question) => {
    const answerRenderCB = question.question_type === 'multiple' ? renderMultichoiceAnswer : renderAnswer
    return (
      <li key={question.id}>
        <p>{question.question_text}</p>
        <div>{question.answers.map(answerRenderCB)}</div>
        {question.comment && <div>{question.comment}</div>}
      </li>
    )
  }
  const renderAnswer = ({answer_text, is_correct, user_answer}) => {
    const wrong = !is_correct && user_answer
    const color = wrong ? 'red' : is_correct ? 'rgb(6 181 6)' : undefined
    return (
      <RadioButton color={color} label={answer_text} value={is_correct}/>
    )
  }
  
  const renderMultichoiceAnswer = ({answer_text, is_correct, user_answer}) => {
    const wrong = !is_correct && user_answer
    const color = wrong ? 'red' : is_correct ? 'rgb(6 181 6)' : undefined
    return (
      <Checkbox color={color} label={answer_text} value={is_correct}/>
    )
  }


  const renderChart = () => {
    const {total_user_points, max_points} = data
    const points_data = [
      { name: 'Получено баллов', value: total_user_points },
      { name: 'Недополучено баллов', value: max_points - total_user_points },
    ];
    const actualColor = interpolateColor(total_user_points / max_points)
    return (
      <>
        <div>
          <span style={{color: actualColor}}>{total_user_points} / {max_points}</span>
        </div>
        <PieChart width={200} height={200}>
          <Pie
            dataKey="value"
            data={points_data}
            outerRadius={80}
            innerRadius={40}
          >
            {points_data.map((_, ind) => <Cell fill={ind ? '#f5f5f5' : actualColor}/>)}
          </Pie>
        </PieChart>
      </>
    )
  }

  if (!data || loading) return <ReactLoader/>;

  return (
    <div className={styles.wrapper}>
      
      <h1>Тест пройден</h1>

      <div>{renderChart()}</div>

      <div>
        <b>ФИО:</b>
        <span>{data.fio}</span>
      </div>

      <div>
        <b>Название образовательной программы:</b>
        <span>{parse(data.test_title)}</span>
      </div>

      <div>
        <b>Дата и время прохождения:</b>
        <span>{dateTime(data.last_answer_date)}</span>
      </div>

      {wrongAnswers()}      

    </div>
  )
}

export default Stats