import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { deleteTest, getTestsList } from '../../actions/editor'
import styles from './styles.module.scss'
import { NavLink, useNavigate } from 'react-router-dom'
import AddEditTestModal from '../../components/AddEditTestModal'
import ReactLoader from 'react-loader'
import { IoIosClose } from 'react-icons/io'
import Confirmation from '../../components/Confirmation/Confirmation'
import parse from 'html-react-parser'
import { IoStatsChartSharp } from "react-icons/io5";


const EditorList = () => {
  
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { list, loading } = useSelector(state => state.editor)

  const [modal, setModal] = useState(false)
  const [deleteId, setDeleteId] = useState(null)

  useEffect(() => {
    dispatch(getTestsList())
  }, [])
  
  const goToTest = (id) => () => {
    navigate(`/test_editor/${id}`)
  }

  const deleteTestHandler = (id) => (e) => {
    e.stopPropagation()
    setDeleteId(id)
  }

  const renderTest = (test) => {
    return (
      <li key={test.id} onClick={goToTest(test.id)}>
        <div>
          <p>{parse(test.title)}</p>
          <IoIosClose color='red' size={46} title='Удалить' onClick={deleteTestHandler(test.id)}/>
        </div>
      </li>
    )
  }
  
  const setModalState = (state) => () => setModal(state)

  const acceptConfirmation = () => {
    dispatch(deleteTest(deleteId))
    setDeleteId(null)
  }

  return (
    <ReactLoader loaded={!loading || modal}>
      <div className={styles.wrapper}>
        <div>
          <button onClick={setModalState(true)}>Добавить</button>
          
          <NavLink to={'/statistic'}>
            <button>
              <IoStatsChartSharp/>
              Статистика
            </button>
          </NavLink>
        </div>
        <ol>{list?.map(renderTest)}</ol>
        
        <AddEditTestModal
          open={modal}
          closeModal={setModalState(false)}
          addMode={true}
        />

        <Confirmation
          open={!!deleteId}
          acceptConfirmation={acceptConfirmation}
          rejectConfirmation={() => setDeleteId(null)}
        >
          Вы уверены, что хотите удалить тест?
        </Confirmation>
      </div>
    </ReactLoader>
  )
}

export default EditorList