export const version = '13.02.3'


export const BACKEND_URL_PREFIX = process.env.REACT_APP_URL
export const BACKEND_DOMAIN = process.env.REACT_APP_BACKEND_DOMAIN


export const MENU = [
  {name: 'Главная', path: '/', link: false},
  {name: 'Реестр', path: 'https://help.obr-mo.ru/', link: true},
  {name: 'Образование-МО', path: 'https://obr-mo.ru/', link: true},
  {name: 'Контакты', path: '/contacts', link: false},
]