import { api } from "../App";
import { SET_STATS_DATA, SET_STATS_LOADING, SET_TESTS_STAT_DATA, SET_TESTS_STAT_LOADING } from "./types";

export const getStats = (userId) => async (dispatch, getState) => {
  try {
    dispatch({ type: SET_STATS_LOADING, payload: true });
    const { data: payload } = await api.get(`/user/stats/${userId}`)
    dispatch({ type: SET_STATS_DATA, payload })
  } catch (ERR) {
    console.log(ERR)
  } finally {
    dispatch({ type: SET_STATS_LOADING, payload: false });
  }
};


export const getTestStats = () => async (dispatch, getState) => {
  try {
    dispatch({ type: SET_TESTS_STAT_LOADING, payload: true });
    const { data: payload } = await api.get(`/stats/tests`)
    dispatch({ type: SET_TESTS_STAT_DATA, payload })
  } catch (ERR) {
    console.log(ERR)
  } finally {
    dispatch({ type: SET_TESTS_STAT_LOADING, payload: false });
  }
};
