import React, { useEffect } from 'react'
import styles from './styles.module.scss'
import { useDispatch, useSelector } from 'react-redux'
import { getTestStats } from '../../actions/stats'
import ReactLoader from 'react-loader'

const cellValues = [
  'Название теста',
  'Описание теста',
  'Минимальное количество баллов',
  'Пользователей проходивших тест',
  'Пользователей прошедших тест'
]

const Stats = () => {
  
  const dispatch = useDispatch()
  const {loading, data} = useSelector(state => state.tests_stat)


  useEffect(() => {
    dispatch(getTestStats())
  }, [])
  
  const renderRow = (row) => <tr>{cellValues.map(value => <td>{row[value]}</td>)}</tr>

  if (!data || loading) return <ReactLoader/>;
  
  return (
    <div className={styles.wrapper}>
      
      <h2>Статистика</h2>

      <table>
        <thead>
          <tr>
            <th>Название</th>
            <th>Описание</th>
            <th>Минимальное количество баллов</th>
            <th>Всего пользователей, проходивших тест</th>
            <th>Всего пользователей, прошедших тест</th>
          </tr>
        </thead>
        <tbody>
          {data.map(renderRow)}
        </tbody>
      </table>

    </div>
  )
}

export default Stats